var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.project && _vm.project.questionnaire)?_c('div',{staticClass:"dna__wrapper"},[_c('confirm',{ref:"confirm"}),(_vm.project.questionnaire)?_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-row',{staticStyle:{"padding":"16px 0px","min-height":"80px","width":"100%"}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('h-text-input',{attrs:{"maxlength":50,"counter":50,"plain":"","tip":"Informe um nome para o seu projeto. O nome pode conter até 50 caracteres e será utilizado tanto para ser referenciado na sua lista de projetos quanto para ser o título do certificado.","placeholder":"Informe o nome do projeto","label":"Nome do projeto"},model:{value:(_vm.project.titulo),callback:function ($$v) {_vm.$set(_vm.project, "titulo", $$v)},expression:"project.titulo"}})],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"absolute"},[_c('h-tip-dialog',{attrs:{"overlay":true,"label":"Gostaria de saber como seu projeto relaciona diferentes dimensões de\n            impacto?","tip":`<p>
            O cálculo dos múltiplos impactos, que chamamos de DNA Cultural, por
            trazer a cultura ao centro desse caleidoscópio de setores, é feito
            em duas partes.
          </p>
          <p>
            Primeiro, selecione um ponto em cada escala abaixo, ponderando quão
            importante você acha que é estabelecer relações entre a cultura e
            cada setor elencado.
          </p><p>
            Agora, atribua a cada questão uma nota, de 0 a 10 de acordo com os extremos da escala. Ao final do preenchimento será gerado um gráfico do DNA Cultural de seu projeto, baseado nas informações que você forneceu.
</p>`},model:{value:(_vm.showTip),callback:function ($$v) {_vm.showTip=$$v},expression:"showTip"}})],1),_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-items',[_vm._l((_vm.project.questionnaire.groups),function(group,groupIdx){return [[_c('v-stepper-step',{key:`${group.title}-header}`,attrs:{"complete":_vm.currentStep > groupIdx + 1,"step":groupIdx + 1},on:{"click":function($event){_vm.step = groupIdx + 1}}},[_vm._v(" "+_vm._s(group.title)+" ")]),_c('v-stepper-content',{key:`${group.title}-content}`,staticClass:"stepper-content",attrs:{"step":groupIdx + 1}},[(
                      _vm.currentStep <= _vm.project.questionnaire.groups.length - 1
                    )?_c('v-sheet',{staticClass:"py-8 px-6"}):_vm._e(),_vm._l((group.questions),function(question,questionIdx){return _c('v-card',{key:`${groupIdx}-${questionIdx}`,attrs:{"elevation":"2"}},[_c('v-card-title',{staticClass:"question--title"},[_vm._v(" "+_vm._s(questionIdx + 1)+") "+_vm._s(question.title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","right":"","color":"primary","disabled":_vm.editQuestionIdx === questionIdx},on:{"click":function($event){_vm.editQuestionIdx = questionIdx}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),(_vm.currentStep === groupIdx + 1)?_c('v-card-text',{staticClass:"px-4 pb-0"},[(question.type === 'range')?[_c('div',{staticClass:"question-type--range"},[_c('label',{staticClass:"primary--text"},[_vm._v(_vm._s(question.labels[0]))]),_c('v-radio-group',{attrs:{"hide-details":"","dense":"","error":_vm.$v.$anyDirty &&
                                  _vm.$v.project.questionnaire.groups.$each[
                                    groupIdx
                                  ].questions.$each[questionIdx].value.$error,"row":_vm.$vuetify.breakpoint.mdAndUp,"disabled":(question.value &&
                                  questionIdx !== _vm.editQuestionIdx) ||
                                  !!question.other},on:{"change":function($event){_vm.editQuestionIdx = -1}},model:{value:(question.value),callback:function ($$v) {_vm.$set(question, "value", $$v)},expression:"question.value"}},_vm._l((Array(
                                  question.options[1]
                                )),function(option,idx){return _c('v-radio',{key:`${question.title}-${idx})`,attrs:{"hide-detail":"","disabled":!!question.other,"ripple":false,"value":idx + 1,"label":`${idx + 1}`}})}),1),_c('label',{staticClass:"primary--text"},[_vm._v(_vm._s(question.labels[1]))])],1)]:_vm._e(),(question.type === 'list')?[_c('div',{staticClass:"question-type--list"},_vm._l((Array(
                                question.options[1]
                              )),function(option,idx){return _c('v-checkbox',{key:`${question.title}-${idx})`,attrs:{"hide-detail":"","ripple":false,"value":idx + 1,"label":question.labels[idx]},model:{value:(question.value),callback:function ($$v) {_vm.$set(question, "value", $$v)},expression:"question.value"}})}),1)]:_vm._e(),(question.tip)?[_c('div',{staticClass:"absolute"},[_c('h-tip-dialog',{attrs:{"overlay":true,"value":_vm.questionnaireTipIdx === questionIdx,"label":"Informação","tip":`<p>${question.tip}</p>`},on:{"change":function($event){_vm.questionnaireTipIdx = -1}}})],1),_c('div',{staticClass:"d-flex py-4",staticStyle:{"width":"100%"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":"","color":"info","disabled":_vm.questionnaireTipIdx === questionIdx},on:{"click":function($event){_vm.questionnaireTipIdx = questionIdx}}},[_c('span',{staticStyle:{"width":"100%","word-break":"break-words"}},[_vm._v("Compensação de emissão")]),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-information-outline")])],1),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-spacer'):_vm._e()],1)]:_vm._e()],2):_vm._e(),(
                          _vm.$v.$anyDirty &&
                            _vm.$v.project.questionnaire.groups.$each[groupIdx]
                              .questions.$each[questionIdx].value.$error
                        )?_c('v-alert',{attrs:{"color":"red","dense":"","text":"","type":"error"}},[_vm._v(" Por favor, assinale uma resposta para esta pergunta ")]):_vm._e()],1)}),_c('v-sheet',{staticClass:"py-8 px-6"},[_c('v-row',[_c('v-spacer'),(
                          _vm.currentStep <= _vm.project.questionnaire.groups.length - 1
                        )?_c('v-btn',{attrs:{"large":_vm.$vuetify.breakpoint.smAndDown,"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.navigateForward(groupIdx)}}},[_c('span',[_vm._v("Próxima etapa")])]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-spacer'):_vm._e()],1)],1)],2)]]})],2)],1)],1),(_vm.currentStep === _vm.project.questionnaire.groups.length)?_c('v-card-actions',{staticClass:"px-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"right":"","large":"","color":"primary","loading":_vm.loading},on:{"click":_vm.saveQuestionnaire}},[_c('span',[_vm._v("Analisar DNA")]),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-dna")])],1)],1):_vm._e()],1):_c('v-skeleton-loader',{staticClass:"pa-16",attrs:{"type":"article, article, actions"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }