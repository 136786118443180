<template>
  <div class="dna__wrapper" v-if="project && project.questionnaire">
    <confirm ref="confirm"></confirm>

    <v-card elevation="0" v-if="project.questionnaire">
      <v-card-title>
        <v-row style="padding: 16px 0px; min-height: 80px; width: 100%;">
          <v-col cols="12" md="12" sm="12">
            <h-text-input
              v-model="project.titulo"
              :maxlength="50"
              :counter="50"
              plain
              tip="Informe um nome para o seu projeto. O nome pode conter até 50 caracteres e será utilizado tanto para ser referenciado na sua lista de projetos quanto para ser o título do certificado."
              placeholder="Informe o nome do projeto"
              label="Nome do projeto"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div class="absolute">
          <h-tip-dialog
            :overlay="true"
            v-model="showTip"
            label="Gostaria de saber como seu projeto relaciona diferentes dimensões de
            impacto?"
            v-bind:tip="
              `<p>
            O cálculo dos múltiplos impactos, que chamamos de DNA Cultural, por
            trazer a cultura ao centro desse caleidoscópio de setores, é feito
            em duas partes.
          </p>
          <p>
            Primeiro, selecione um ponto em cada escala abaixo, ponderando quão
            importante você acha que é estabelecer relações entre a cultura e
            cada setor elencado.
          </p><p>
            Agora, atribua a cada questão uma nota, de 0 a 10 de acordo com os extremos da escala. Ao final do preenchimento será gerado um gráfico do DNA Cultural de seu projeto, baseado nas informações que você forneceu.
</p>`
            "
          ></h-tip-dialog>
        </div>
        <v-stepper v-model="currentStep" vertical>
          <v-stepper-items>
            <template v-for="(group, groupIdx) in project.questionnaire.groups">
              <template>
                <v-stepper-step
                  :complete="currentStep > groupIdx + 1"
                  :step="groupIdx + 1"
                  @click="step = groupIdx + 1"
                  :key="`${group.title}-header}`"
                >
                  {{ group.title }}
                </v-stepper-step>
                <v-stepper-content
                  class="stepper-content"
                  :step="groupIdx + 1"
                  :key="`${group.title}-content}`"
                >
                  <v-sheet
                    class="py-8 px-6"
                    v-if="
                      currentStep <= project.questionnaire.groups.length - 1
                    "
                  >
                    <!-- <v-row>
                      <v-spacer />
                      <v-btn :large="$vuetify.breakpoint.smAndDown" color="primary" :loading="loading" @click="navigateForward(groupIdx)">
                        <span>Próxima etapa</span>
                      </v-btn>
                      <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
                    </v-row> -->
                  </v-sheet>
                  <template>
                    <v-card
                      elevation="2"
                      v-for="(question, questionIdx) in group.questions"
                      :key="`${groupIdx}-${questionIdx}`"
                    >
                      <v-card-title class="question--title">
                        {{ questionIdx + 1 }})
                        {{ question.title }}
                        <v-spacer />
                        <v-btn
                          icon
                          right
                          color="primary"
                          :disabled="editQuestionIdx === questionIdx"
                          @click="editQuestionIdx = questionIdx"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text
                        class="px-4 pb-0"
                        v-if="currentStep === groupIdx + 1"
                      >
                        <template v-if="question.type === 'range'">
                          <div class="question-type--range">
                            <label class="primary--text">{{
                              question.labels[0]
                            }}</label>
                            <v-radio-group
                              hide-details
                              dense
                              @change="editQuestionIdx = -1"
                              v-model="question.value"
                              :error="
                                $v.$anyDirty &&
                                  $v.project.questionnaire.groups.$each[
                                    groupIdx
                                  ].questions.$each[questionIdx].value.$error
                              "
                              :row="$vuetify.breakpoint.mdAndUp"
                              :disabled="
                                (question.value &&
                                  questionIdx !== editQuestionIdx) ||
                                  !!question.other
                              "
                            >
                              <v-radio
                                v-for="(option, idx) in Array(
                                  question.options[1]
                                )"
                                hide-detail
                                :disabled="!!question.other"
                                :key="`${question.title}-${idx})`"
                                :ripple="false"
                                :value="idx + 1"
                                :label="`${idx + 1}`"
                              ></v-radio>
                            </v-radio-group>
                            <label class="primary--text">{{
                              question.labels[1]
                            }}</label>
                          </div>
                        </template>

                        <template v-if="question.type === 'list'">
                          <div class="question-type--list">
                            <v-checkbox
                              v-for="(option, idx) in Array(
                                question.options[1]
                              )"
                              v-model="question.value"
                              hide-detail
                              :key="`${question.title}-${idx})`"
                              :ripple="false"
                              :value="idx + 1"
                              :label="question.labels[idx]"
                            ></v-checkbox>
                          </div>
                        </template>
                        <template v-if="question.tip">
                          <div class="absolute">
                            <h-tip-dialog
                              :overlay="true"
                              :value="questionnaireTipIdx === questionIdx"
                              @change="questionnaireTipIdx = -1"
                              label="Informação"
                              v-bind:tip="`<p>${question.tip}</p>`"
                            ></h-tip-dialog>
                          </div>
                          <div class="d-flex py-4" style="width: 100%">
                            <v-spacer />
                            <v-btn
                              text
                              small
                              color="info"
                              :disabled="questionnaireTipIdx === questionIdx"
                              @click="questionnaireTipIdx = questionIdx"
                            >
                              <span
                                style="width: 100%; word-break: break-words;"
                                >Compensação de emissão</span
                              >
                              <v-icon class="ml-2"
                                >mdi-information-outline</v-icon
                              >
                            </v-btn>
                            <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
                          </div>
                        </template>
                      </v-card-text>
                      <v-alert
                        v-if="
                          $v.$anyDirty &&
                            $v.project.questionnaire.groups.$each[groupIdx]
                              .questions.$each[questionIdx].value.$error
                        "
                        color="red"
                        dense
                        text
                        type="error"
                      >
                        Por favor, assinale uma resposta para esta pergunta
                      </v-alert>
                    </v-card>
                  </template>
                  <v-sheet class="py-8 px-6">
                    <v-row>
                      <v-spacer />
                      <v-btn
                        :large="$vuetify.breakpoint.smAndDown"
                        color="primary"
                        :loading="loading"
                        @click="navigateForward(groupIdx)"
                        v-if="
                          currentStep <= project.questionnaire.groups.length - 1
                        "
                      >
                        <span>Próxima etapa</span>
                      </v-btn>
                      <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
                    </v-row>
                  </v-sheet>
                </v-stepper-content>
              </template>
            </template>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions
        class="px-4"
        v-if="currentStep === project.questionnaire.groups.length"
      >
        <v-spacer />
        <v-btn
          right
          large
          color="primary"
          :loading="loading"
          @click="saveQuestionnaire"
        >
          <span>Analisar DNA</span>
          <v-icon class="ml-2">mdi-dna</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader
      v-else
      class="pa-16"
      type="article, article, actions"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import merge from "lodash.merge";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";

import Confirm from "../../components/custom/HConfirm";
import HTipDialog from "../../components/custom/HTipDialog";
import HTextInput from "../../components/custom/HTextInput";

export default {
  mixins: [validationMixin],
  name: "DNA",
  components: {
    Confirm,
    HTipDialog,
    HTextInput,
  },
  watch: {
    project: {
      handler(newVal, oldVal) {
        if (newVal && oldVal && !this.dirty) {
          this.dirty = true;
        }
      },
      deep: true,
    },
  },
  data: () => ({
    step: 1,
    project: null,
    dirty: false,
    loading: false,
    showTip: true,
    editQuestionIdx: -1,
    questionnaireTipIdx: -1,

    emptyProject: {
      titulo: "Novo Projeto",
      proponente: "",
      resumo: "",
      segmento: null,
      valorDireitosAutorais: 0,
      acervoImovel: "",
      questionnaire: require("../../assets/questionnaire.json"),
      locais: [
        {
          dia: 0,
          estabelecimento: null,
          estado: "",
          cidade: "",
          lotacao: 0,
          qtApresentacoes: 1,
          despesas: [
            {
              local: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
              excursionista: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
              nacional: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
              internacional: {
                publico: 0,
                despesaTransporteChegada: 0,
                despesatransporteRedondezas: 0,
                despesaHospedagem: 0,
                despesaAlimentos: 0,
                despesaOutros: 0,
              },
            },
          ],
        },
      ],
      fontesRecurso: [
        {
          code: "FRPUB",
          value: "",
          label: "Públicos R$",
          hint:
            "Valor total originado de editais, leis de incentivo e fundos públicos",
        },
        {
          code: "FRPRV",
          value: "",
          label: "Privados R$",
          hint:
            "Valor total de patrocínio não incentivado, tais como doações e apoios financeiros de empresas privadas",
        },
        {
          code: "FRRP",
          value: "",
          label: "Próprios R$",
          hint:
            "Total de valores previstos por arrecadação com ingressos, vendas de produtos e capital próprio",
        },
      ],
    },
  }),
  computed: {
    currentStep: {
      get: function get() {
        return this.step;
      },
      set: function set(newValue) {
        this.step = newValue;
        if (newValue > this.project.questionnaire.groups.length) {
          this.step = this.project.questionnaire.groups.length;
        }

        if (newValue < 1) {
          this.step = 1;
        }
      },
    },
  },
  validations: {
    project: {
      questionnaire: {
        groups: {
          $each: {
            questions: {
              $each: {
                value: {
                  required: requiredIf(function(model) {
                    return model.type !== "list";
                  }),
                },
              },
            },
          },
        },
      },
    },
  },
  async beforeMount() {
    const projectId = this.$route.params.id;
    this.project = await this.fetchProject(projectId);
    if (!this.project) {
      this.$router.push("/404");
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.dirty) {
      const confirmed = await this.confirmBeforeLeaving();
      if (confirmed) {
        this.project = null;
        next();
      } else {
        next(false);
      }
    } else {
      this.project = null;
      next();
    }
  },
  methods: {
    calculateScore() {
      const questions = this.project.questionnaire.groups.flatMap((group) =>
        group.questions.flatMap((question) => question)
      );
      const qualitativeQuestions = questions.filter(
        ({ type }) => type === "range"
      );
      const quantitativeQuestions = questions.filter(
        ({ type }) => type === "list"
      );

      const qualitativeQuestionsScore = qualitativeQuestions.reduce(
        (prev, cur) => prev + cur.coefficient * cur.value,
        0
      );

      const getODSScore = (value) => {
        switch (value?.length ?? 0) {
          case 0: {
            return 0;
          }
          case 1: {
            return 4;
          }
          case 2: {
            return 7;
          }
          default: {
            return 10;
          }
        }
      };

      const quantitativeQuestionsScore = quantitativeQuestions.reduce(
        (prev, cur) => prev + getODSScore(cur.value),
        0
      );

      this.project.odsScore = quantitativeQuestionsScore;
      this.project.questionnaireScore = qualitativeQuestionsScore;
    },
    async fetchProject(projectId) {
      let project = null;
      if (!projectId || projectId === "new") {
        project = {
          ...this.emptyProject,
        };
        this.project = project;
        await this.saveProject(0);
        this.$router.push("/projects/dna/" + this.project._id);
      } else {
        project = await this.$api()
          .post("project/list", {
            _id: projectId,
          })
          .then((results) => {
            return results.data[0];
          })
          .catch((err) => {
            this.jsonData = err;
          });

        this.project = project;
      }
      if (
        typeof project.questionnaire !== "object" ||
        !project.questionnaire.groups ||
        !project.questionnaire.groups.find(
          (g) => g.questions.filter((q) => q.type).length > 0
        )
      ) {
        // if (typeof project.questionnaire !== "object" || project.questionnaire?.groups?.questions?.length === 0) {
        project.questionnaire = merge(
          require("../../assets/questionnaire.json"),
          project.questionnaire
        );
      }

      return project;
    },
    async navigateForward(currentGroupIdx) {
      this.$v.$touch();
      if (
        this.$v.project.questionnaire.groups.$each[currentGroupIdx].$invalid
      ) {
        return;
      }

      this.saveProject();

      this.step = this.step + 1;
      this.$v.$reset();
    },
    async saveQuestionnaire() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.submitStatus = "ERROR";
        } else {
          this.loading = true;
          this.calculateScore();
          await this.saveProject();
          this.dirty = false;
          this.$nextTick(() => {
            this.loading = false;
            this.$router.push("/projects/report/" + this.project._id);
          });
        }
      } catch (error) {
        this.jsonData = error;
        this.loading = false;
      }
    },
    async saveProject() {
      try {
        this.loading = true;
        const caller = this.$api();
        const {
          data: { result },
        } = await caller.post("project/save", this.project);
        if (!this.project._id && result._id) {
          this.project._id = result._id;
        }
        this.dirty = false;
        this.loading = false;
      } catch (error) {
        this.jsonData = error;
        this.loading = false;
      }
      return true;
    },
    goBack() {
      this.$router.push({
        path: "/projects",
      });
    },
    confirmBeforeLeaving() {
      return this.$refs.confirm.open("Saindo sem salvar", "Tem certeza?", {
        color: "red",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dna__wrapper {
  max-width: 1024px;
  margin: 0 auto;
}

.v-stepper__step {
  cursor: pointer;
}

@media only screen and (max-width: 640px) and (orientation: portrait) {
  .v-stepper__header {
    margin: 0 6px;
  }

  .v-stepper__content {
    padding: 8px !important;
  }

  .v-stepper--vertical .v-stepper__step {
    margin: 0px;
    padding: 16px;
  }

  .v-stepper--vertical .v-stepper__content {
    margin: -5px -48px -16px 24px !important;
    padding: 16px 60px 16px 16px !important;
  }
}

/* Landscape iPad */
@media only screen and (min-width: 1366px) and (orientation: landscape) {
  .dna__wrapper .v-card__actions {
    bottom: 16px;
    width: 100%;
  }
}

/*Portrait iPad */
@media only screen and (min-width: 1024px) and (orientation: portrait) {
  .dna__wrapper .v-card__actions {
    bottom: 16px;
    width: 100%;
  }
}

.question-type--other {
  width: 75%;

  @media only screen and (max-width: 640px) and (orientation: portrait) {
    width: 100%;
  }
}

.question-text-input {
  width: 100%;

  & > div {
    width: 100%;
  }
}

.v-input--selection-controls {
  margin-top: 12px;
  margin-bottom: 12px;
}

.question--title {
  padding: 16px;
  padding-bottom: 0;
  font-size: 1rem;
  word-break: break-word;
  font-weight: 400;
}

.question-type--list {
  padding: 16px 12px;
  grid-template-columns: auto !important;
  justify-items: start !important;

  @media only screen and (max-width: 640px) and (orientation: portrait) {
    grid-template-rows: auto !important;
  }
}

.question-type--list,
.question-type--range {
  padding: 16px 12px;
  margin: 16px 0;
  display: inline-grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  background-color: GhostWhite;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: auto;

  /*Portrait iPad */
  @media only screen and (min-width: 1024px) and (orientation: portrait) {
    padding: 24px;
  }

  .v-radio {
    margin-right: 8px !important;
  }

  label {
    align-self: center;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
  }

  @media only screen and (max-width: 640px) and (orientation: portrait) {
    grid-template-columns: auto;
    grid-template-rows: 32px auto 32px;

    label {
      align-self: end;

      &:last-of-type {
        align-self: start;
      }
    }
  }
}
</style>

<style scoped>
::v-deep .v-input--selection-controls__input {
  margin-right: 2px;
}

::v-deep .v-input--selection-controls__input i {
  font-size: 1.2rem;
}
</style>
